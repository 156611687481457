import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-qr-show',
  standalone: true,
  imports: [CommonModule, ClipboardModule, MatButtonModule, MatIconModule],
  templateUrl: './qr-show.component.html',
  styleUrls: ['./qr-show.component.scss'],
})
export class QrShowComponent implements OnInit {
  @Input() data = '';

  imageUrl = '';

  ngOnInit(): void {
    this.imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&&color=005a20&data=${this.data}`;
  }

  async downloadImage() {
    const blob = await fetch(this.imageUrl).then(res => res.blob());
    const data =  URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = data;
    a.download = "qrCode.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
